<template>
  <v-container class="fill-heigt pt-0" fluid>
    <v-row>
      <v-col cols="12" md="12">
        <v-btn
          icon
          color="primary"
          @click="$router.back()"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="12">
         <!-- <pre>
      {{$i18n.locale}}
    </pre> -->
        <details-card />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DetailsCard from '../components/DetailsCard_share.vue'
export default {
  components: { DetailsCard },
  name: 'Datails'
}
</script>